.order_card{
    background-color: white;
    margin: 1rem 1rem 0rem 1rem;
    border: 1px solid rgb(237, 237, 237);
    border-radius: 0.7rem;
    cursor: pointer;
}
.order_card:hover{
    border: 1px solid rgb(98, 141, 158);
}
.order_number{
    background-color: red;
    width: 7rem;
    padding: 1rem 0.2rem 1rem 0.5rem;
    border-radius: 1rem;
    background-image: linear-gradient(#09b3d4 0,#77e2f8 100%);
    font-weight: 600;
}
.order_card.active{
    border: 2px solid #15afcd;
}