::-webkit-scrollbar{width:2px;height:6px;}
::-webkit-scrollbar-button{width:2px;height:6px;}

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #f8faf9;
}

*::-webkit-scrollbar
{
	width: 6px;
	background-color: #f8faf9;
}

*::-webkit-scrollbar-thumb
{
	background-color: #21a0b9;
}
*::-webkit-scrollbar-track
{
	background-color: #f8faf9;
}
.pos_container{
    height: 100vh;
    overflow-y: hidden;
}
.pgb_btn_outline{
    color: black;
    border-color: #594e8d;
    padding: 0.65rem 1.8rem 0.65rem;
}
.pgb_btn_outline:hover{
    background: #594e8d;
}
/* .pgb_btn_outline:focus{
    background: #594e8d;
} */
.pgb_btn_outline.active{
    background: #9586DE;
}

.products_tab{
    background-color: #f8faf9;
    /* height: 100vh; */
}

.category_div{
    padding-bottom: 1rem;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}
.category_card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.15);
    box-sizing: content-box;
    color: #555;
    font-size: 14px;
    padding: 10px 5px;
    text-align: center;
    text-decoration: none;
    width: 80px;
    word-break: break-all;
    border: 1px solid white;
    margin-left: 1rem;
    margin-right: 1rem;
}
.category_card button {
    cursor: pointer;
}
.category_card.active{
    box-shadow: 0 0 2px 1px #c3dfe4;
    color: #09b3d4;
    border-color: #09b3d4;
}
.catIcon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.category_card span {
    font-size: 76px;
    width: 40px;
    height: 40px;
}
.category_card p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.search_input{
    height: 3rem;
    border: 1px solid #ced4da;
    border-right: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
}
.search_input:focus {
    border: 1px solid #ced4da;
    border-right: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
}
.search_input_icon{
    border-left: none;
    background-color: #ffff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
}
.basic_btn{
    background-color: white;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
}
@media screen and (max-width: 480px) {
    .basic_btn{
        margin: 0.6rem;
    }
}
.pos_product_card {
    background-color: white;
    box-shadow: -1px 4px 10px 0px rgba(0, 0, 0, 0.21);
    cursor: pointer;
    /* height: 9rem; */
    text-align: center;
    border-radius: 6px;
    /* margin: 0.6rem; */
    margin: 0rem 0rem 1.3rem 0rem;
    min-height: 9.8rem;
    align-items: center;
    display: flex;
    position: relative;
}
.pos_product_card:hover{
    opacity: 90%;
    background-color: #e1f8f8;
    transition: 0.4s;
}
.pos_product_card img{
    padding: 33px 3px;
}
.pos_product_details {
    align-self: center;
    font-size: 1rem;
    padding: 8px 8px 8px 0px;
}
.pos_product_details h2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 0.8rem;
    font-weight: 600;
    overflow: hidden;
    width: 96%;
}
.pos_product_img{
     -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 0.8rem;
    font-weight: 600;
    overflow: hidden;
}

.cart_product_image{
    display: -webkit-box;
    font-size: 0.8rem;
    font-weight: 600;
    overflow: hidden;
}
.in_stock{
    color: rgb(21, 183, 26);
    font-weight: 600;
}
.out_stock{
    color: rgb(183, 21, 21);
    font-weight: 600;
    font-size: 0.8rem;
}
.cart_section{
width: 100%;
}
.cart_section h2{
    border-top: 1px solid rgb(221, 221, 221);
    border-bottom: 1px solid rgb(221, 221, 221);
}

.cart_totals {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 45%;
    z-index: 10;
    background-color: white;
    position: fixed;
    bottom: 0;
    padding-left: 0.5rem;
    padding-right: 1rem;
}
.cart_totals h6, .cart_totals p{
    margin-bottom: 0.3rem;
}
.discount_card{
    background-color: rgb(241, 238, 238);
    padding: 6% 12%;
    width: 8.5rem;
    margin-top: 0.6rem;
}
.discount_card:hover{
    background-color: rgb(238, 241, 241);
}
.cart_order_btn{
    align-items: center;
    background-image: linear-gradient(#09b3d4 0,#94e0ef 100%);
    color: black;
    display: grid;
    font-size: 20px;
    font-weight: 600;
    grid-template-columns: auto max-content max-content;
    height: max-content;
    border-radius: 6px;
    padding: 0;
    text-decoration: none;
    width: 100%;
}
.cart_order_btn i{
    font-size: 1rem;
}
.product_list{
    margin: 0.6rem 0rem 0rem 0rem;
    height: 63vh;
    overflow-y: scroll;
}
.cart_items{
    padding-bottom: 4.5rem;
    height: 46vh;
    overflow-x: hidden;
    overflow-y: scroll;
}
.discount_type:checked{
    background-color: #09b3d4;
    border-color: #594e8d;
}

.quantity_button_group{
    --tw-border-opacity: 1;
    border-color: rgb(230 230 230);
    border-width: 1px;
    border-radius: .375rem;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    /* height: 2.75rem; */
    display: flex;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    /* background-color: rgb(221, 220, 220); */
}

.quantity_button{
    border-radius: .375rem;
    /* border-inline-end-width: 1px; */
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .3s;
    --tw-border-opacity: 1;
    border-color: rgb(221, 220, 220/var(--tw-border-opacity));
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 2.5rem;
    /* height: 88%; */
    display: flex;
    border: 0 solid #f9f9f9;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    height: 2.30rem;
    /* background-color: #c8cccc; */
}
.quantity_button:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(3, 137, 163);
    /* background-color: rgb(3, 137, 163); */
}

.quantity_value{
    /* width: 3rem; */
    /* padding: 0; */
    /* padding-left: 0 !important; */
}
   
@media screen and (max-width: 768px) {
    .mobile_cart_btn_group{
        width: 26%;
    }
    .mobile_cart_btn{
        width: 2rem;
    }
  }
  @media screen and (max-width: 426px) {
    .mobile_cart_btn_group{
        width: 46%;
        float: right;
    }
    .mobile_cart_btn{
        width: 1.9rem;
        height: 66%;
        border-radius: 24%;
    }
    .remove-cart-button-mobile{
        text-align: end !important;
    }
  }
  @media screen and (max-width: 376px) {
    .mobile_cart_btn_group{
        width: 50.5%;
    }
    .mobile_cart_btn{
        width: 1.9rem;
    }
  }

  @media screen and (max-width: 320px) {
    .mobile_cart_btn_group{
        width: 54.5%;
    }
    .mobile_cart_btn{
        width: 1.9rem;
    }
  }

  .quantity_price_label{
    font-size: 0.8rem;
  }
  
  .customer_section{
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
.customer_add_btn{
    background-image: linear-gradient(#09b3d4 0,#94e0ef 100%);
}
.customer_class_search_result{
    position: absolute;
    top: 6%;
    width: 17.4rem;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    /* padding: 0.4rem; */
    background-color: aliceblue;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.15);
}
.customer_class_search_result p{
    padding: 0.4rem;
    cursor: pointer;
    border-bottom: 0.1px solid rgb(195, 195, 195);
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.cart_item_card {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 4px 0px;
}
.cart_item_card h5{
    font-size: 1rem;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.cart_item_card .center_col {
    align-items: center;
    display: inline-flex;
}
.address_div{
    margin-bottom: 0;
}
.selected_customer{
    border: 1px solid #16d3e9;
    padding: 0.4rem;
    border-radius: 0.6rem;
    background-image: linear-gradient(#09b3d4 0,#94e0ef 100%);
    /* color: rgb(131, 10, 10); */
}
.select-search-container{
    --select-search-selected: #16d3e9 !important;
    width: 100% !important;
}
.select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input, .select-search-container:not(.select-search-is-disabled) .select-search-input:hover{
border-color: #16d3e9 !important;
}
.form-check-input:checked {
    background-color: #0098b8;
    border-color: #0098b8;
}
.swal2-radio label, .swal2-checkbox label{
    flex: 0 0 46%;
    width: 46%;
    font-size: 1.1rem;
    border: 0.1px solid #91b4bb;
    margin-bottom: 0.5rem;
    padding: 0.1rem;
}
.swal2-radio label:hover{
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-box-shadow: 0 1px 2px -2px #00000029,0 3px 6px #0000001f,0 5px 12px 4px #00000017;
    box-shadow: 0 1px 2px -2px #00000029,0 3px 6px #0000001f,0 5px 12px 4px #00000017;
}
.swal2-radio, .swal2-checkbox{
    align-items: baseline;
}

.discount_badge{
    position: absolute;
    background: aliceblue;
    border-radius: 0.25rem;
    top: 9px;
    right: 0px;
    padding: 0 0.5rem;
    text-align: center;
    color: orange;
    font-weight: 700;
    font-size: 1rem;
}
.old-price {
    color: #adadad;
    font-size: 11px;
    margin: 0 0 0 3px;
    text-decoration: line-through;
    font-weight: 400;
}

.product_attribute_cart {
    box-shadow: -1px 4px 10px 0px rgba(0, 0, 0, 0.21);
    padding: 1rem;
    cursor: pointer;
    border-radius: 0.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.product_attribute_cart:hover {
    transition: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform: translateY(-5px);
    transition: all .3s linear;
}
.product_attribute_cart .discount_badge {
    border-radius: 0.3rem;
    top: 0px;
}
@keyframes shake {
    0%, 50%, 100% {
        transform: translateX(-1px);
    }
    25%, 75% {
        transform: translateX(1px);
    }
}
/* @keyframes shake {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
   } */
.product_attribute_cart:active {
    animation: shake 0.5s ease-in-out;
}